import { Injectable } from "@angular/core";
import { User } from "./user";
import { APIService } from "./API.service";
import Amplify, { API } from 'aws-amplify';

@Injectable({
  providedIn: "root"
})
export class ProfileService {
  constructor(private api: APIService) {}

  async getProfile(id: string) {
    let result = await this.api.GetUser(id);

    if (!result) {
      return null;
    }

    const user = new User(
      result.id,
      result.email,
      result.image,
      result.b1URL,
      result.b1UserName,
      result.b1Password,
      result.b1Company
    );

    return user;
  }

  async testConnection(user: User):Promise<any> {
    return new Promise((resolve, reject) => {
      let apiName = "restApiSapAssist";
      let path = "/testConnection";
      let myInit = {
        headers: {'Content-type': "application/json"},
        queryStringParameters:{id:user.id }
      }
        
      //GET result of connectivity test
        API.get(apiName, path, myInit)
        .then(response => {
         console.log(response)
         resolve(response)
        })
        .catch(error => {
          console.error(error);
          reject(error)
        });
    })
  }
  
}

