import { Component, OnInit } from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})

export class AuthComponent implements OnInit {

  signUpConfig: any = {
    hiddenDefaults: [
      'email',
      'phone_number',
    ],
    signUpFields: [
      {
        label: 'Email',
        key: 'username',
        required: true,
        displayOrder: 1,
        type: 'string'
      },
      // {
      //   label: 'Email',
      //   key: 'email',
      //   required: true,
      //   displayOrder: 0,
      //   type: 'string'
      // },
      {
        label: 'Password',
        key: 'password',
        required: true,
        displayOrder: 3,
        type: 'password'
      }
    ]

  }

  constructor(public amplifyService: AmplifyService, public router: Router) {
    this.amplifyService = amplifyService;
    this.amplifyService.authStateChange$
      .subscribe(authState => {
        if (authState.state === 'signedIn') {
          this.router.navigate(['/profile']);
        }
      });

}
  ngOnInit() {
  }

}
