export class User {
    constructor(
        public id: string,
        public email: string,
        public imageUrl: string,
        public b1URL: string,
        public b1UserName: string,
        public b1Password: string,
        public b1Company: string,
    ){}
}