import { Component, OnInit } from "@angular/core";
import { APIService } from "../API.service";
import { User } from "../user";
import { Auth } from "aws-amplify";
import { Router } from "@angular/router";
import { ProfileService} from '../profile.service'
import { ToastrService } from 'ngx-toastr';



@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"]
})
export class ProfileComponent implements OnInit {
  userId: string;
  userName: string;
  showPhoto: boolean;
  userCreated: boolean;

  user = new User("", "", "", "", "", "", "");
  
  constructor(private api: APIService, 
              private router: Router,  
              private profileService: ProfileService,
              private toastr: ToastrService
              ) {}

  ngOnInit() {
    this.showPhoto = false;
    Auth.currentAuthenticatedUser({
      bypassCache: false
    })
      .then(async user => {
        this.userId = user.attributes.sub;
        this.userName = user.attributes.email;

        // let result = await this.api.GetUser(this.userId);
        let result = await this.profileService.getProfile(this.userId)


        if (!result) {
          this.userCreated = false;
          this.user = new User("", "", "", "", "", "", "");
        } else {
          this.userCreated = true;
          this.showPhoto = !!result.imageUrl;
          this.user = result
        }
      })
      .catch(err => console.log(err));
  }

  //Upload Image to S3 Bucket
  async onImageUploaded(e) {
    this.user.imageUrl = e.key;
    if (this.userCreated) {
      await this.api.UpdateUser({
        id: this.userId,
        image: this.user.imageUrl
      });
    }
    this.showPhoto = true;
  }

  editPhoto() {
    this.showPhoto = false;
  }

  getType(): string {
    return this.userCreated ? "UpdateUser" : "CreateUser";
  }

  // Call connection testing from profile.services
  async testConnection(){

    this.toastr.info("Please Wait", 'Testing Connection...');
    this.profileService.testConnection(this.user)
    .then(result => {
      this.toastr.success(null, 'Connection Succeeded!');
     })
     .catch(error => {
      this.toastr.error(error.response.status+" - "+ error.response.statusText, 'Connection Failed');
     });


  }

  async updateProfile() {
    
    let b1User = this.user.b1UserName
    b1User = b1User.replace(/\/\//g, "/").replace(/\\\\/g, '\\')

    let URL = this.user.b1URL
    if (URL.slice(-1) !== '/'){
      URL +='/'
    }
    
    const user =  {
      id: this.userId,
      email: this.userName,
      image: this.user.imageUrl,
      b1URL: URL,
      b1UserName: b1User,
      b1Password: this.user.b1Password,
      b1Company: this.user.b1Company
    };
    await this.api[this.getType()](user).then(d => {
      this.toastr.success(null, 'Configurations Saved!');
    })
    .catch(err => console.log(err));
  }

  logOut() {
    Auth.signOut({ global: true })
      .then(data => {
        this.router.navigate(["/auth"]);
      })
      .catch(err => console.log(err));
  }

  formatB1User(){
    
    
  }
}
